@use '../includes' as *;

$bp: $md;

.detail_hero {
  position: relative;
  margin-block-end: $g20;

  // gradient at the top of the hero
  &::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0 0 auto;
    z-index: 1;
    width: 100%;
    height: 23%;
    opacity: 0.9;
    background: linear-gradient(to bottom, $paynes-gray 0%, transparent 96.45%);
    pointer-events: none;
  }

  @include breakpoint($bp) {
    display: grid;
    grid-template: 'overlay .' 1fr;
    grid-template-columns: 42% 58%;
    margin-block-end: 0;
    /* stylelint-disable */
    grid-template-rows: 1fr;
    aspect-ratio: 2.25 / 1;

    &:has(.sponsorship_row__top) {
      grid-template-areas:
        'overlay .'
        'sponsorship sponsorship';
      grid-template-rows: 1fr 100px;
    }
  }
}

.detail_hero_background_image,
.detail_hero_video {
  aspect-ratio: 16 / 9;
  background-color: $paynes-gray;
  margin-block-end: -$g56;
  mask-composite: intersect;
  mask-image: linear-gradient(
    to top,
    transparent 5px,
    rgb(0 0 0 / 30%) 20px,
    black 60px
  );

  @include breakpoint($xxs) {
    margin-block-end: -$g64;
  }

  @include breakpoint($xs) {
    margin-block-end: -$g80;
  }

  @include breakpoint($bp) {
    margin-block-end: 0;
    position: absolute;
    inset: 0 0 0 auto;
    mask-composite: intersect;
    mask-image: linear-gradient(
        to right,
        transparent 2%,
        rgb(0 0 0 / 30%) 20%,
        black 44%
      ),
      linear-gradient(
        to top,
        transparent 5px,
        rgb(0 0 0 / 30%) 20px,
        black 60px
      );

    &:has(~ .sponsorship_row__top) {
      inset-block-end: 10%;
      mask-image: linear-gradient(
          to right,
          transparent 2%,
          rgb(0 0 0 / 30%) 20%,
          black 44%
        ),
        linear-gradient(to top, transparent 2%, rgb(0 0 0 / 30%) 6%, black 18%);
    }
  }
}

.detail_hero_mezzanine_video_initializer {
  position: relative;
}

.detail_hero_show_image {
  position: relative;

  img {
    width: 100%;
    height: auto;
  }
}

.detail_hero_back_button {
  position: absolute;
  inset: $g20 $g20 auto auto;
  z-index: 1;

  @include breakpoint($xl) {
    inset: $g32 $g32 auto auto;
  }
}

.detail_hero_overlay {
  display: flex;
  container-type: inline-size;
  z-index: 2;
  grid-area: overlay;
  flex-direction: column;
  justify-content: center;
  gap: $g12;
  max-width: calc(100% - var(--spacing-inline));
  margin-block-start: calc(var(--responsive-row-gap) / 2);
  padding-inline-start: var(--spacing-inline);
  pointer-events: none;
  position: relative;
  a,
  button {
    pointer-events: auto;
  }

  @include breakpoint($xs) {
    max-width: calc(70% - var(--spacing-inline));
  }

  @include breakpoint($bp) {
    position: relative;
    z-index: 2;
    gap: $g16;
    max-width: none;
    height: 100%;
    margin-block: 0;
  }

  @include breakpoint($xl) {
    gap: $g20;
  }
}

.detail_hero_show_name {
  @include h1;

  max-width: calc(100vw - var(--spacing-inline));

  img {
    width: auto;
    // Used this clamp generator for the values
    // https://clamp-generator.netlify.app/
    max-height: clamp(4rem, 10.696cqw + 1.85rem, 11.5rem);
  }
}

.detail_hero_broadcast_info {
  @include h6;

  text-transform: uppercase;
}

.detail_hero_description {
  max-width: calc(95vw - var(--spacing-inline));
  text-wrap: pretty;

  @include body-copy-3($color: $pebble);

  @include breakpoint($md-only) {
    @include typography-truncate(4);

    max-width: 90%;
  }

  @include breakpoint($md-xxl-only) {
    @include body-copy-2($color: $white);

    font-size: 12px;
  }

  @include breakpoint($xxl) {
    @include body-copy-1($color: $white);
  }
}

.detail_hero_overlay_buttons {
  display: flex;
  gap: $g16;
  min-width: 460px;
}

.detail_hero_overlay_buttons_website_button {
  display: none;

  @container (min-width: 400px) {
    display: inline-flex;
  }
}

.detail_hero_overlay_buttons_kabob_menu {
  display: inline-block;

  @container (min-width: 400px) {
    display: none;
  }
}

.detail_hero_overlay_buttons_kabob_menu_external_link_icon {
  display: flex;
  align-items: first baseline;
  gap: $g8;

  &:not(:hover) {
    fill: white;
  }
}

.sponsorship_row__top {
  z-index: 2;
  grid-area: sponsorship;
  align-self: end;
}
